import defaults from 'lodash.defaults'

import type { TypesenseInstantsearchAdapterOptions } from 'typesense-instantsearch-adapter'

const defaultConfig: TypesenseInstantsearchAdapterOptions['server'] = {
  // 🚨 The default key, make sure it's read-only!
  apiKey: process.env.NEXT_PUBLIC_TYPESENSE_SEARCH_ONLY_API_KEY ?? '',
  nodes: [
    {
      host: process.env.NEXT_PUBLIC_TYPESENSE_HOST ?? '',
      port: Number.parseInt(process.env.NEXT_PUBLIC_TYPESENSE_PORT ?? ''),
      protocol: process.env.NEXT_PUBLIC_TYPESENSE_PROTOCOL ?? '',
    },
  ],
  numRetries: 8,
  connectionTimeoutSeconds: 1,
}

export function getConfig(customConfig?: Partial<typeof defaultConfig>) {
  return defaults(customConfig, defaultConfig)
}
