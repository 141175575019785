import styled from 'styled-components'

export const StSearchFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
  align-items: start;
  width: 100%;
`

export const StSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  width: 100%;
`

export const StResults = styled.div`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space4};
`
