import { useSearchBox } from '@repo/typesense/client'
import * as React from 'react'

import { Input } from '../../../shared/components/input'

import type { SearchBoxProps } from '@repo/typesense/client'

export const SearchBox = (props?: SearchBoxProps) => {
  const ref = React.useRef<HTMLInputElement>(null)
  const { refine, ...rest } = useSearchBox(props)

  React.useEffect(() => {
    if (ref.current) {
      ref.current.focus()
    }
  }, [])

  return (
    <Input
      ref={ref}
      onChange={(event) => refine(event.currentTarget.value)}
      placeholder="Search..."
      {...rest}
    />
  )
}
