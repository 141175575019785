import { faRight } from '@fortawesome/pro-solid-svg-icons/faRight'
import { useInfiniteHits } from '@repo/typesense/client'

import { Loader } from '../../../shared/components/loader'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useStoryblokCercleGeneralSettings } from '../../../storyblok/hooks/useStoryblokCercleGeneralSettings'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'

import {
  StBackgroundContainer,
  StCardContainer,
  StImage,
  StArticleTitle,
  StExtraInfoContainer,
  StLinkContainer,
  StLinkCta,
  StIcon,
  StContentContainer,
  StOverviewContainer,
} from './Hits.styled'

import type { ArticleSearchItem } from '@repo/typesense/schemas'
import type { StoryblokImage } from 'types'

function Hit({ hit }: { hit: ArticleSearchItem }) {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)
  const { isLoading } = useStoryblokCercleGeneralSettings()

  if (isLoading) {
    return (
      <StCardContainer>
        <Loader />
      </StCardContainer>
    )
  }

  return (
    <StCardContainer href={`/${hit.slug}`} title={hit.title}>
      {hit.thumbnail?.filename && (
        <StImage
          image={hit.thumbnail as StoryblokImage}
          width={100}
          height={100}
        />
      )}

      <StBackgroundContainer>
        <StContentContainer>
          <StArticleTitle>{hit.title}</StArticleTitle>
          <StExtraInfoContainer>
            <StLinkContainer>
              <StLinkCta>
                {t('read_article_cta')} <StIcon icon={faRight} />
              </StLinkCta>
            </StLinkContainer>
          </StExtraInfoContainer>
        </StContentContainer>
      </StBackgroundContainer>
    </StCardContainer>
  )
}

export function Hits() {
  const { items } = useInfiniteHits<ArticleSearchItem>()

  return items.length === 0 ? (
    <StOverviewContainer>
      <p>No results found</p>
    </StOverviewContainer>
  ) : (
    <StOverviewContainer>
      {items.map((item) => (
        <Hit key={item.objectID} hit={item} />
      ))}
    </StOverviewContainer>
  )
}
