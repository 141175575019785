import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'

import { getConfig } from './config'

import type { TypesenseInstantsearchAdapterOptions } from 'typesense-instantsearch-adapter'

type CreateSearchAdapterOptions = Partial<
  ReturnType<typeof getConfig> & {
    additionalSearchParameters?: TypesenseInstantsearchAdapterOptions
  }
>

function createSearchAdapter(options?: CreateSearchAdapterOptions) {
  return new TypesenseInstantSearchAdapter({
    server: getConfig(options),
    additionalSearchParameters: {
      query_by: 'title,name',
      queryByWeights: '4,2',
      numTypos: '1',
      typoTokensThreshold: 1,
      ...options?.additionalSearchParameters,
    },
  })
}

export function getSearchClient(props?: CreateSearchAdapterOptions) {
  return createSearchAdapter(props).searchClient
}

export { InstantSearchNext } from 'react-instantsearch-nextjs'
export * from 'react-instantsearch'
