import { getSearchClient, InstantSearchNext } from '@repo/typesense/client'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import { Hits } from './components/Hits'
import { SearchBox } from './components/SearchBox'
import { StSearchContainer } from './SearchForm.styled'

export const SearchForm = () => {
  const { locale } = useRouter()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <InstantSearchNext
      searchClient={getSearchClient({
        additionalSearchParameters: {
          // @ts-expect-error - it exists
          filter_by: `locale:=${locale}`,
        },
      })}
      indexName="cercle_brugge-articles"
    >
      <StSearchContainer>
        <SearchBox ref={inputRef} placeholder="Search..." />
        <Hits />
      </StSearchContainer>
    </InstantSearchNext>
  )
}
