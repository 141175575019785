import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import {
  ArticleTitle,
  BodySmallLinkStyles,
} from '../../../shared/components/typography'

export const StOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

// Clamping the title to 2 lines
export const StArticleTitle = styled(ArticleTitle)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const StCardContainer = styled.a`
  min-height: 100px;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  text-decoration: none;
`

export const StBackgroundContainer = styled.div`
  flex: 1;

  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
`

export const StContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: ${({ theme }) =>
    theme.UI.SpacingPx.Space4 + ' ' + theme.UI.SpacingPx.Space6};
`

export const StImage = styled(StoryblokServiceImage)`
  position: relative;
  width: 100px;
  height: 100px;
`

export const StExtraInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

export const StLinkContainer = styled.div`
  display: none;
`

export const StLinkCta = styled.span`
  ${BodySmallLinkStyles}
  color: ${({ theme }) => theme.Colors.Neutrals.Lighter};
`

export const StIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.Colors.Neutrals.Lighter};
  position: relative;
  font: 16px;
  top: 1px;
  padding-left: ${({ theme }) => theme.UI.SpacingPx.Space1};
`
